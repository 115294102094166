export function init() {

	(function(){

		function smallHeader() {
			if($(window).scrollTop() > 150) {
				$("header").addClass("small-header");	
			} else {
				$("header").removeClass("small-header");	
			}
		}

		$(window).load(function() {
	
			// Add gap for submenu if too close to border)
			$('nav#main-nav > ul > li:last-child').prev('li').andSelf().each(function() {
		        if ($(this).children('ul.submenu').length > 0) {
					var pageRight = parseInt($(window).width() - ($("#page-container").offset().left + $("#page-container").outerWidth()), 10);
					var elRight = parseInt( ($(window).width() - ($(this).offset().left + $(this).outerWidth())) - pageRight, 10);
					if (elRight < 150) { $(this).children('ul.submenu').addClass('add-gap'); }	
				}
		    });
			
			// Responsive Navigation
			$('header').on("click", ".mobile-nav-toggle", function() { 
				$('#menu').toggleClass('menu-is-open'); 
				return false;
			});
			
			$('#main-nav').on("click", "li > a", function() {
				var thisItem = $(this); 
				var thisParent = $(this).parent('li'); 
				if(thisItem.siblings('ul').length > 0 && thisItem.siblings('ul').css('display') === 'none') {
					thisItem.siblings('ul').slideDown(400);
					thisParent.siblings('li').children('ul').slideUp(400);
					return false;	
				}
			});
			
			smallHeader();
		});


		$(window).scroll(function() { 
			smallHeader(); 
		});

	})($);
	
}
import '../helpers/helpers';

import * as slickSliders from '../functions/slickSliders';
import * as googleMap from '../functions/googleMap';
import * as templateScripts from '../functions/templateScripts';
import * as scrollEvent from '../eventHandlers/scrollEvent';
import * as initOdometer from '../functions/initOdometer';

document.addEventListener('DOMContentLoaded', function(){

	var $fancybox = $('a.fancybox'),
		$map = document.getElementById('map'),
		$mainSlider = document.querySelectorAll('.big-image'),
		$odometer = document.querySelector('.odometer'),
		isFirefox = typeof InstallTrigger !== 'undefined',
		isIE = /*@cc_on!@*/false || !!document.documentMode,
		isEdge = !isIE && !!window.StyleMedia;

	templateScripts.init();

	if($mainSlider){
		slickSliders.init();
	}

	if($fancybox.length) {
		$fancybox.fancybox({
			helpers: {
				overlay: {
					locked: false
				}
			}
		});
	}

	if($map) {
		googleMap.init();
	}

	if($odometer){
		scrollEvent.init();
	}

	if(isFirefox){
		$('html').addClass('mozilla');
	}

	if(isIE || isEdge) {
		$('html').addClass('ie');	
	}
});

$(window).resize(function() { 
	if($(window).width() > 1023) { $('#menu').removeClass('menu-is-open'); }
});

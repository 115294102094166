export function init() {

	var $map = document.getElementById('map');

	window.callInitGoogleOptions = function() {
		initGoogleOptions();
	};

	var loadAsyncScript = function() {
		var s = document.createElement('script');

		s.src = 'http://maps.googleapis.com/maps/api/js?sensor=false&key=AIzaSyDc0vCJDTFRC727aQe8KtdgGaMagRRO5kI&callback=callInitGoogleOptions';
		document.body.appendChild(s);
	};

	$(window).bind('load scroll', function(){
		var mapCanvasOffset = $('#map').offset().top - $(window).scrollTop() - 1000;

		if( $(window).scrollTop() > mapCanvasOffset && !$('body').is('.map-loaded') ) {

			$(document).trigger('load-map');
		}
	});
	
	$(document).on('load-map', function(){
		$('body').addClass('map-loaded');
		
		loadAsyncScript();
	});

	function initGoogleOptions() {
		var myLatLng = {lat: 50.023288, lng: 19.910491},
			myOptions = {
	        	zoom: 16,
	        	center: myLatLng,
	        	scrollwheel: false,
	        	mapTypeControl: true,
			    mapTypeControlOptions: {
			        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
			        position: google.maps.ControlPosition.RIGHT_TOP
			    },
			    zoomControl: true,
			    zoomControlOptions: {
			        position: google.maps.ControlPosition.RIGHT_BOTTOM
			    },
			    scaleControl: true,
			    streetViewControl: true,
			    streetViewControlOptions: {
			        position: google.maps.ControlPosition.RIGHT_BOTTOM
			    },
	        	mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [{featureType:"landscape",stylers:[{saturation:-100},{lightness:65},{visibility:"on"}]},{featureType:"poi",stylers:[{saturation:-100},{lightness:51},{visibility:"simplified"}]},{featureType:"road.highway",stylers:[{saturation:-100},{visibility:"simplified"}]},{featureType:"road.arterial",stylers:[{saturation:-100},{lightness:30},{visibility:"on"}]},{featureType:"road.local",stylers:[{saturation:-100},{lightness:40},{visibility:"on"}]},{featureType:"transit",stylers:[{saturation:-100},{visibility:"simplified"}]},{featureType:"administrative.province",stylers:[{visibility:"off"}]},{featureType:"administrative.locality",stylers:[{visibility:"off"}]},{featureType:"administrative.neighborhood",stylers:[{visibility:"on"}]},{featureType:"water",elementType:"labels",stylers:[{visibility:"on"},{lightness:-25},{saturation:-100}]},{featureType:"water",elementType:"geometry",stylers:[{hue:"#ffff00"},{lightness:-25},{saturation:-97}]}],
	        },
	        map = new google.maps.Map(document.getElementById("map"),myOptions),
	        urlPath = $('.google-map').attr('data-temp-dir');
       
        var image = new google.maps.MarkerImage(urlPath+"/img/marker.png");
        
        var marker = new google.maps.Marker({
            map: map, 
            icon: image,
            position: map.getCenter()
        });

        var contentString = $('.google-map').attr('data-infobox-text');

        var infowindow = new google.maps.InfoWindow({
	        content: contentString
	    });

	    google.maps.event.addListener(marker, 'click', function() {
		   infowindow.open(map,marker);
		});
	}
	
}
export function init() {

	var $mainSlider = document.getElementById('banner-slider'),
		$sliderBox = document.querySelector('.big-image');

	if($mainSlider) {
		if($mainSlider.hasClass('banner-slider--arrows') && $('.banner-item').length > 1){
			$('.banner-slider--arrows').on('init', function(event, slick, currentSlide, nextSlide){
				var $activeSlide = $('#banner .slick-track .slick-active');

				$activeSlide.find('h4').removeClass('hide-elem').addClass('animate-init');
				$activeSlide.find('h1').removeClass('hide-elem').addClass('animate-init2');
				$activeSlide.find('.slider-content').removeClass('hide-elem').addClass('animate-init3');
			});

			$('.banner-slider--arrows').on('afterChange', function(event, slick, currentSlide, nextSlide){
				var $activeSlide = $('#banner .slick-track .slick-active');

				$activeSlide.prev().find('h4').removeClass('animate-init').addClass('hide-elem');
				$activeSlide.prev().find('h1').removeClass('animate-init2').addClass('hide-elem');
				$activeSlide.prev().find('.slider-content').removeClass('animate-init3').addClass('hide-elem');

				$activeSlide.next().find('h4').removeClass('animate-init').addClass('hide-elem');
				$activeSlide.next().find('h1').removeClass('animate-init2').addClass('hide-elem');
				$activeSlide.next().find('.slider-content').removeClass('animate-init3').addClass('hide-elem');

				$activeSlide.find('h4').addClass('animate-init').removeClass('hide-elem');
				$activeSlide.find('h1').addClass('animate-init2').removeClass('hide-elem');
				$activeSlide.find('.slider-content').addClass('animate-init3').removeClass('hide-elem');
			});

			$('.banner-slider--arrows').slick({
				infinite: true,
				speed: 1000,
				slidesToShow: 1,
				autoplay: true,
				autoplaySpeed: 5000,
				fade: true,
				useTransform: true,
				cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
				dots: false,
				arrows: true,
				prevArrow: '<span class="slick-prev"></span>',
				nextArrow: '<span class="slick-next"></span>'
			});
		} else if($mainSlider.hasClass('banner-slider--dots') && $('.banner-item').length > 1) {
			$('.banner-slider--dots').slick({
				infinite: true,
				speed: 1000,
				slidesToShow: 1,
				autoplay: true,
				autoplaySpeed: 5000,
				fade: true,
				useTransform: true,
				cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
				dots: true,
				arrows: false
			});
		}
	}

	if($sliderBox) {
		$('.big-image').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			adaptiveHeight: true,
			fade: true,
			asNavFor: '.thumbs',
			arrows: false
		});

		$('.thumbs').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 3,
			slidesToScroll: 1,
			autoplay: false,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			focusOnSelect: true,
			asNavFor: '.big-image',
			arrows: true
		});
	}

}